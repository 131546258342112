import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Sobre() {

  return (
    <Container>
       <div className="top-spacing"></div>
      <Row>
        <Col className="mt-5 mb-5 text-center">
        <h2>Sobre o Coruja Sábia</h2>
        <p className="lead">Saiba mais sobre a plataforma que chegou para revolucionar a Educação Digital.</p>
        <iframe src="https://player.vimeo.com/video/383274404?h=9e6be356d7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" className="mw-100 vh-100 vw-100" width="auto" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Institucional"></iframe>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="mt-5 mb-5 text-center">
          <h4>Nossa história</h4>
          <p>O Coruja Sábia surgiu em 2018 como um canal informativo no YouTube, que logo evoluiu para um canal de videoaulas curtas de Biologia com foco no público de pré-vestibular. Em 2019 criamos uma plataforma própria de apoio ao ensino presencial com videoaulas curtas de todas as disciplinas, passando a oferecer outros materiais pedagógicos, como banco de exercícios e simulados. Em 2021, iniciamos a produção de mais de mil aulas completas para o ensino médio e pré-vestibular com investimentos realizados em tecnologia e contando com a colaboração de uma equipe com mais de 70 profissionais.</p>
          <h4>Nossos valores</h4>
          <p>Caráter, integridade, dignidade do indivíduo, comprometimento e excelência são os valores que guiam todos os aspectos do Coruja Sábia, passando pela criação de conteúdo pela equipe pedagógica, até a colaboração de profissionais técnicos e assistentes operacionais.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Sobre;