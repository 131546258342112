import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PoliticaPrivacidade() {

  return (
    <Container>
       <div className="top-spacing"></div>
      <Row>
        <Col className="mt-5 mb-5">
          <h2>Política de Privacidade</h2>
          <div id="texte_a_afficher">
          <p>Este site é mantido e operado por<span>&nbsp;</span><span id="span_id_controlador_nome">Coruja Sabia Assessoria Comercialização e Produções Pedagógicas Ltda</span>.</p>
          <p>Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso site. Ao fazê-lo, agimos na qualidade de<span>&nbsp;</span><strong>controlador</strong><span>&nbsp;</span>desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).</p>
          <p>Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:</p>
          <p>– Quem deve utilizar nosso site<br />– Quais dados coletamos e o que fazemos com eles;<br />– Seus direitos em relação aos seus dados pessoais; e<br />– Como entrar em contato conosco.</p>
          <p><strong>1.<span>&nbsp;</span>Dados que coletamos e motivos da coleta<br /></strong></p>
          <p>Nosso site coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.</p>
          <p><em>1. Dados pessoais fornecidos expressamente pelo usuário<strong><br /></strong></em></p>
          <p>Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso site:</p>
          <p><span id="span_id_dados_fornecidos_especifica">– Nome Completo;<br />– Telefone;<br />– E-mail;<br />– CPF;<br />– Endereço;<br />– Cidade;<br />– Estado;</span></p>
          <p>A coleta destes dados ocorre nos seguintes momentos:</p>
          <p><span id="span_id_dados_fornecidos_momento">– Quando o usuário faz uma compra;<br />– Quando o usuário recebe acesso à plataforma através de uma escola parceira;<br />– Quando o usuário preenche um formulário de contato;<br />– Quando o usuário preenche um formulário de uma promoção;</span></p>
          <p>Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:</p>
          <p><span id="span_id_dados_fornecidos_finalidade">– Para que nossos usuários possam utilizar a nossa plataforma de ensino;<br />– Para que nossos usuários possam entrar em contato conosco;<br />– Para que possamos enviar e-mails de notificação relacionados à experiência de usuário;<br />– Para que possamos enviar e-mails promocionais.</span></p>
          <p><em>2. Dados pessoais obtidos de outras formas</em></p>
          <p>Nós coletamos os seguintes dados pessoais de nossos usuários:</p>
          <p><span id="span_id_dados_coletados_especifica">– Endereço de IP;<br />– Dados de geolocalização;<br />– Informações demográficas;<br />– Dados de transações feitas através do site;<br />– Dados de aprendizado (vídeos assistidos, simulados realizados, redações enviadas).</span></p>
          <p>A coleta destes dados ocorre nos seguintes momentos:</p>
          <p><span id="span_id_dados_coletados_momento">– Quando um usuário acessa o site;<br />– Quando um usuário utiliza o seu painel de estudante;<br />– Quando um usuário faz uma compra;</span></p>
          <p>Estes dados são coletados com as seguintes finalidades:</p>
          <p><span id="span_id_dados_coletados_finalidade">– Garantir a segurança e a autenticidade das transações feitas no site;<br />– Personalizar a experiência do usuário;<br />– Cumprir determinação legal de armazenamento de registros de acesso a aplicações.</span></p>
          <p><em>3. Dados sensíveis</em></p>
          <p><strong>Não</strong><span>&nbsp;</span>serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim,<span>&nbsp;</span><strong>não</strong><span>&nbsp;</span>haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</p>
          <p><em>4. Dados de crianças e adolescentes<br /></em></p>
          <p>Nós coletamos os seguintes dados de crianças e adolescentes:</p>
          <p><span id="span_id_dados_crianca_especifica">– Nome Completo;<br />– Telefone;<br />– E-mail;<br />– CPF;<br />– Endereço;<br />– Cidade;<br />– Estado;</span></p>
          <p>A coleta de dados de crianças e adolescentes acontece nos seguintes momentos:</p>
          <p><span id="span_id_dados_crianca_momento">No momento em que o assinante informa estes dados através de formulários de contato ou no momento de compra.</span></p>
          <p>Os dados de crianças e de adolescentes que coletamos são utilizados exclusivamente com as seguintes finalidades:</p>
          <p><span id="span_id_dados_crianca_finalidade">Para que os usuários possam acessar a nossa plataforma de ensino.</span></p>
          <p>O tratamento de dados de crianças e adolescentes é realizado com base no melhor interesse da criança ou do adolescente.</p>
          <p><em>5. Cookies</em></p>
          <p><em>Cookies</em><span>&nbsp;</span>são pequenos arquivos de texto baixados automaticamente em seu dispositivo quando você acessa e navega por um site. Eles servem, basicamente, para seja possível identificar dispositivos, atividades e preferências de usuários.</p>
          <p>Os<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do site.</p>
          <p><em>a.<span>&nbsp;</span>Cookies do<span>&nbsp;</span></em><em>site</em></p>
          <p>Os<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>do site são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo site.</p>
          <p>As informações coletadas por meio destes<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.</p>
          <p><em>b.<span>&nbsp;</span>Cookies de terceiros<br /></em></p>
          <p>Alguns de nossos parceiros podem configurar<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>nos dispositivos dos usuários que acessam nosso site.</p>
          <p>Estes<span>&nbsp;</span><em>cookies</em>, em geral, visam possibilitar que nossos parceiros possam oferecer seu conteúdo e seus serviços ao usuário que acessa nosso site de forma personalizada, por meio da obtenção de dados de navegação extraídos a partir de sua interação com o site.</p>
          <p>O usuário poderá obter mais informações sobre os<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>de terceiro e sobre a forma como os dados obtidos a partir dele são tratados, além de ter acesso à descrição dos<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>utilizados e de suas características, acessando o seguinte<span>&nbsp;</span><em>link</em>:</p>
          <p><span id="span_id_link_cookies_terceiros">Google Analytics: https://support.google.com/analytics/answer/7318509?hl=pt-BR<br />Pagar.me: https://pagar.me/aviso-de-privacidade/</span></p>
          <p>As entidades encarregadas da coleta dos<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>poderão ceder as informações obtidas a terceiros.</p>
          <p><em>c.<span>&nbsp;</span>Gestão de<span>&nbsp;</span>cookies</em></p>
          <p>O usuário poderá se opor ao registro de<span>&nbsp;</span><em>cookies</em><span>&nbsp;</span>pelo site, bastando que desative esta opção no seu próprio navegador. Mais informações sobre como fazer isso em alguns dos principais navegadores utilizados hoje podem ser acessadas a partir dos seguintes<span>&nbsp;</span><em>links</em>:</p>
          <p>Internet Explorer:<br />https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies</p>
          <p>Safari:<br />https://support.apple.com/pt-br/guide/safari/sfri11471/mac</p>
          <p>Google Chrome:<br />https://support.google.com/chrome/answer/95647?hl=pt-BR&amp;hlrm=pt</p>
          <p>Mozila Firefox:<br />https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam</p>
          <p>Opera:<br />https://www.opera.com/help/tutorials/security/privacy/</p>
          <p>A desativação dos<span>&nbsp;</span><em>cookies</em>, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.</p>
          <p><em>6. Coleta de dados não previstos expressamente</em></p>
          <p>Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.</p>
          <p>Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do site.</p>
          <p><strong><br />2.<span>&nbsp;</span>Compartilhamento de dados pessoais com terceiros</strong></p>
          <p>Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o façamos para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.</p>
          <p><span><strong><br /></strong></span><strong>3.</strong><span>&nbsp;</span><strong>Por quanto tempo seus dados pessoais serão armazenados</strong></p>
          <p>Os dados pessoais coletados pelo site são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do site e as disposições legais ou regulatórias aplicáveis.</p>
          <p>Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.</p>
          <p><span><strong><br /></strong></span><strong>4. Bases legais para o tratamento de dados pessoais<span><br /></span></strong></p>
          <p>Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.</p>
          <p>Todas as Nossas atividades de tratamento de dados pessoais possuem uma base legal que as fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que utilizamos para operações de tratamento de dados pessoais específicas podem ser obtidas a partir de nossos canais de contato, informados ao final desta Política.</p>
          <p><strong><span><br /></span>5.<span>&nbsp;</span></strong><span><strong>Como o titular pode exercer seus direitos</strong></span></p>
          <p>Os titulares de dados pessoais tratados por nós poderão exercer seus direitos por meio do formulário disponibilizado no seguinte caminho:<span>&nbsp;</span><span id="span_id_formulario_link">https://contato.corujasabia.com.br/</span>. Alternativamente, se desejar, o titular poderá enviar um<span>&nbsp;</span><em>e-mail</em><span>&nbsp;</span>ou uma correspondência ao nosso Encarregado de Proteção de Dados Pessoais. As informações necessárias para isso estão na seção “Como entrar em contato conosco” desta Política de Privacidade.</p>
          <p>Os titulares de dados pessoais tratados por nós poderão exercer seus direitos a partir do envio de mensagem ao nosso Encarregado de Proteção de Dados Pessoais, seja por<span>&nbsp;</span><em>e-mail</em><span>&nbsp;</span>ou por correspondência. As informações necessárias para isso estão na seção “Como entrar em contato conosco” desta Política de Privacidade.</p>
          <p>Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.</p>
          <p><strong>6.</strong><strong><span>&nbsp;</span>Medidas de segurança no tratamento de dados pessoais<br /></strong></p>
          <p>Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.</p>
          <p>As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.</p>
          <p>Entre as medidas de segurança adotadas por nós, destacamos as seguintes:</p>
          <p><span id="span_id_seguranca_especifica">– armazenamento de senhas utilizando hashes criptográficos;<br />– restrições de acessos a banco de dados;<br />– monitoramento de acesso aos servidores</span></p>
          <p>Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro – como em caso de ataques de<span>&nbsp;</span><em>hackers</em><span>&nbsp;</span>ou<span>&nbsp;</span><em>crackers</em><span>&nbsp;</span>ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.</p>
          <p>De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.</p>
          <p><strong>7. Alterações nesta política<br /></strong></p>
          <p>A presente versão desta Política de Privacidade foi atualizada pela última vez em:<span>&nbsp;</span><span id="span_id_data_termo">17/03/2021</span>.</p>
          <p>Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>
          <p>Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.</p>
          {/* <p><strong><br />8.</strong><strong><span>&nbsp;</span>Como entrar em contato conosco</strong></p>
          <p>Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:</p>
          <p><em>E-mail</em>:<span>&nbsp;</span><span id="span_id_encarregado_email">suporte@corujasabia.com.br</span></p>
          <p>Endereço postal:<span>&nbsp;</span><span><span id="span_id_encarregado_endereco">Rua Giselda de Mello Silva 65 CEP 14027-125 – Ribeirão Preto – SP</span></span></p> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PoliticaPrivacidade;