import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { faDna } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Planos from "../components/planos";
import Depoimentos from "../components/Depoimentos";
import Icn6 from "../assets/img/icn-6.png";
import Icn16 from "../assets/img/icn-16.png";
import Icn19 from "../assets/img/icn-19.png";
import Icn15 from "../assets/img/icn-15.png";
import IcnCap from "../assets/img/icn-cap.png";
import ImgAldo from "../assets/img/aldo.png";
import Teste from "../assets/img/img-estudante.png";

function PaginaInicial() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <section id='header'>
        <Container>
          <Row>
            <Col lg={12}>
              <h1>
                Mais que conteúdo,somos focados em <b>desempenho</b> para você ser aprovado.
              </h1>
              <p className='pe-lg-5'>Conheça nossos cursinhos preparatórios para vestibular.</p>
              {/* <a className='btn btn-lg' href='#planos'>
                Ver os planos
              </a> */}
            </Col>
            {/* <Col lg={6}>
              <img src={Teste} className='img-fluid' alt='' />
            </Col> */}
          </Row>
        </Container>
      </section>
      <section id='metodo'>
        <Container>
          <Row>
            <Col lg={6}>
              <h2>Aulas de 3 minutos</h2>
              <p className='lead'>Tão importante quanto estudar, é se lembrar do que estudou.</p>

              <p>
                Nossa metodologia exclusiva contempla mais de 1.000 aulas de 3 minutos com todo o
                conteúdo do Ensino Médio.
              </p>
              <p>
                Assim, você pode fazer revisões constantes para aumentar a retenção do seu estudo.
              </p>
              <p>
                <b>Aulas compactas, focadas em tudo o que importa sobre aquele conteúdo.</b>
              </p>
              {/* <a href='#planos' className='btn btn-lg'>
                Assine Agora
              </a> */}
            </Col>

            <Col lg={6}>
              <a onClick={handleShow}>
                <img src={require("../assets/img/aldo.png")} className='img-fluid' alt='' />
              </a>
              <Modal
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                show={show}
                onHide={handleClose}>
                <iframe
                  src='https://player.vimeo.com/video/343381693?h=cc81b44811&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  width='100%'
                  height='350'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='ALDO BIOENERG&amp;Eacute;TICA'></iframe>
              </Modal>
            </Col>
          </Row>
        </Container>
      </section>

      <section id='beneficios'>
        <Container>
          <Row>
            <Col>
              <h2>Ao adquirir o nosso programa Ultra Estudante você terá acesso a:</h2>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <div className='box d-flex align-items-center'>
                <img src={Icn6} className='rounded' alt='' />
                <p>
                  <b>Acesso de 12 meses</b> à nossa plataforma
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className='box d-flex align-items-center'>
                <img src={IcnCap} className='rounded' alt='' />
                <p>
                  <b>Mais de 4 mil aulas</b> com os conteúdos estratégicos para você ser aprovado no
                  vestibular
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className='box d-flex align-items-center'>
                <img src={Icn16} className='rounded' alt='' />
                <p>
                  <b>Preparatório</b> com aulas de todas as disciplinas, simulados, resumos, plantões de dúvidas, fórum e muito mais.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <div className='box d-flex align-items-center'>
                <img src={Icn19} className='rounded' alt='' />
                <p>
                  <b>Aulas rápidas de três minutos</b>, além das completas, para que você possa
                  revisar muito mais matérias em menos tempo.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className='box d-flex align-items-center'>
                <img src={Icn15} className='rounded' alt='' />
                <p>
                  <b>Curso completo de redação</b> com correções semanais personalizadas, além de
                  Curso de Obras Literárias, incluindo aulas para cada vestibular.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className='box d-flex align-items-center'>
                <img src={IcnCap} className='rounded' alt='' />
                <p>
                  <b>Conteúdo focado no vestibular que você vai prestar</b>: ENEM, Fuvest, Unicamp,
                  Unesp, UFU, UEL, UEM, Fameca, Famema, e mais.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <center>
          {/* <a href='#planos' className='btn-azul'>
            Quero ser um Ultra Estudante
          </a> */}
        </center>
      </section>

      <section id='amostra' className='bg-azul text-light'>
        <Container>
          <Row>
            <Col lg={6}>
              <a onClick={handleShow}>
                <img src={ImgAldo} className='img-fluid' alt='' />
              </a>
              <Modal
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                show={show}
                onHide={handleClose}>
                <iframe
                  src='https://player.vimeo.com/video/343381693?h=cc81b44811&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                  width='100%'
                  height='350'
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen
                  title='ALDO BIOENERG&amp;Eacute;TICA'></iframe>
              </Modal>
            </Col>
            <Col lg={6}>
              <h1>
                O que torna o Coruja Sábia <span>único</span> entre os pré-vestibulares?
              </h1>
              <div className='d-flex align-items-center align-self-center mb-3'>
                <FontAwesomeIcon icon={faVideo} color='#2FBFF9' size='3x' className='pe-3' />{" "}
                <p>Mais de 4.000 aulas modernas e atualizadas.</p>
              </div>
              <div className='d-flex align-items-center align-self-center mb-3'>
                <FontAwesomeIcon icon={faThumbTack} color='#2FBFF9' size='3x' className='pe-3' />{" "}
                <p>Conteúdo específico para o vestibular que você vai prestar.</p>
              </div>
              <div className='d-flex align-items-center align-self-center mb-3'>
                <FontAwesomeIcon
                  icon={faHourglassHalf}
                  color='#2FBFF9'
                  size='3x'
                  className='pe-3'
                />{" "}
                <p>Aulas rápidas de 3 minutos que se adequam à sua rotina de estudos.</p>
              </div>
              {/* <a href='#planos' className='btn btn-lg'>
                Escolha um plano
              </a> */}
            </Col>
          </Row>
        </Container>
      </section>

      <section id='planos'>
        {/* <Planos /> */}
        <Depoimentos />
      </section>
    </div>
  );
}

export default PaginaInicial;
