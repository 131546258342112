import React from "react";
import { Helmet } from "react-helmet";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'

import CountdownTimer from '../CountdownTimer';

import Dep1 from '../assets/img/dep-1.png';
import Dep2 from '../assets/img/dep-2.png';
import Dep3 from '../assets/img/dep-3.png';
import Dep4 from '../assets/img/dep-4.png';
import Dep5 from '../assets/img/dep-5.png';
import Dep6 from '../assets/img/dep-6.png';

import EstudantePR from '../assets/img/estudante-parana.png'

const NINE_HOURS = 9 * 60 * 60 * 1000;
const NOW_IN_MS = new Date().getTime();

const dateTimeAfterNineHours = NOW_IN_MS + NINE_HOURS;

function SuperParana() {

  return (
    <div>
      <Helmet>
        <title>Curso On-line para UFPR, UEL e UEM - Faculdades do Paraná - Coruja Sábia</title>
        <meta name="description" content="Aulas e simulados com foco nas principais faculdades do Paraná e ENEM" />

      </Helmet>
      <section className="hero lp bg-azul">
      <div className="top-spacing"></div>
        <Container>
          <Row>
            <Col lg="12" className='text-center'>
              <h1 className="mb-3 col-lg-10 offset-lg-1 col-xs-12">Prepare-se para ingressar nas principais universidades do <span className="bg-amarelo azul px-2 rounded">Paraná</span>!</h1>
              <h3 className="amarelo">UFPR, UEL e UEM</h3>
              <div className="col-lg-6 col-sm-12 offset-lg-3">
                <p className="lead">Revisão com as aulas que mais caíram, simulados e curso de redação específicos para a universidade que você quer entrar!          
                </p>
                </div>
              <p><a href="#assinar" className='btn-borda mb-3'><FontAwesomeIcon icon={faGraduationCap} /> Começar Agora</a></p>
            </Col>

          </Row>
        </Container>
      </section>

      <div className="bg-verde-metade text-center pb-4">
        <img src={EstudantePR} alt="" className="img-fluid" />
      </div>

      <section id='assinar'>
        <Row>
          <Col className="text-center pt-5">
            <h3>Vantagem Exclusiva para Você</h3>
            <h4 className="py-3"><span className="bg-azul rounded px-2 text-light">Aproveite!</span> Acaba em:</h4>
            <CountdownTimer targetDate={dateTimeAfterNineHours} />
          </Col>
        </Row>
        <Row className="py-5 px-2">
          <Col lg={{ span: 4, offset: 2 }} xs={12}>
            <div className="card lp box-shadow">
              <div className="card-header bg-azul">
                <h4 className="my-0">Super Paraná</h4>
              </div>
              <div className="valor bg-light">
                <p>De <s>R$598,80</s> por R$358,80 em até</p>
                <h3 className="card-title pricing-card-title"><small>12x de</small> R$29,90</h3>
              </div>
              <div className="card-body">
                <ul className="list-unstyled check mt-3 mb-4">
                  <li>Cursos de Redação <b>UFPR</b>, <b>UEL</b> e ENEM</li>
                  <li>Simulados <b>UFPR</b>, <b>UEL</b>, <b>UEM</b> e ENEM</li>
                  <li>Obras Literárias <b>UFPR</b>, <b>UEL</b> e <b>UEM</b></li>
                  <li>As 100+ da <b>UFPR</b>, <b>UEL</b> e ENEM</li>
                  <li>+ de 1.000 Aulas Completas</li>
                  <li>+ de 1.000 Aulas de <b>3 Minutos</b></li>
                  <li>Correções Personalizadas de Redação</li>
                  <li>Plantões On-line <b>Ilimitados</b></li>
                  <li>Fórum de Dúvidas <b>Ilimitadas</b></li>
                  <li>Listas de Exercícios</li>
                  <li>Exercícios Resolvidos</li>
                  <li>Resumos em PDF</li>
                </ul>
                <ul className="list-unstyled uncheck mt-3 mb-4">
                  <li><s>Aulas de Aprofundamento</s></li>
                  <li><s>Aulas Ao-Vivo de Exercícios para Medicina</s></li>
                </ul>
                {/* <p><a href="https://assinatura.corujasabia.com.br/finalizar-assinatura?cart=41" className="btn-assinar">Começar Agora</a></p> */}
                <span className="text-muted">Acesso por <b>12 meses</b></span>
              </div>
            </div>
          </Col>
          <Col lg={4} xs={12}>
            <div className="card lp box-shadow">
              <div className="card-header bg-verde">
                <h4 className="my-0">Super Paraná <span className="bg-azul rounded px-2">MED</span></h4>
              </div>
              <div className="valor bg-light">
                <p>De <s>R$798,80</s> por R$478,80 em até</p>
                <h3 className="card-title pricing-card-title"><small>12x de</small> R$39,90</h3>
              </div>
              <div className="card-body">
              <ul className="list-unstyled check mt-3 mb-4">
                  <li>Cursos de Redação <b>UFPR</b>, <b>UEL</b> e ENEM</li>
                  <li>Simulados <b>UFPR</b>, <b>UEL</b>, <b>UEM</b> e ENEM</li>
                  <li>Obras Literárias <b>UFPR</b>, <b>UEL</b> e <b>UEM</b></li>
                  <li>As 100+ da <b>UFPR</b>, <b>UEL</b> e ENEM</li>
                  <li>+ de 1.000 Aulas Completas</li>
                  <li>+ de 1.000 Aulas de <b>3 Minutos</b></li>
                  <li>Correções Personalizadas de Redação</li>
                  <li>Plantões On-line <b>Ilimitados</b></li>
                  <li>Fórum de Dúvidas <b>Ilimitadas</b></li>
                  <li>Listas de Exercícios</li>
                  <li>Exercícios Resolvidos</li>
                  <li>Resumos em PDF</li>
                  <li>Aulas de Aprofundamento</li>
                  <li>Aulas Ao-Vivo de Exercícios para Medicina</li>
                </ul>
                {/* <p><a href="https://assinatura.corujasabia.com.br/finalizar-assinatura?cart=42" className="btn-assinar">Começar Agora</a></p> */}
                <span className="text-muted">Acesso por <b>12 meses</b></span>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <section>
        <Container>
          <Row>
            <Col className="text-center pt-3">
              <h3>Conheça os diferenciais do Coruja Sábia:</h3>
            </Col>
          </Row>
          <Row>
            <Col sm="6" lg="4">
              <div className="box bg-azul float-lg-end">
                <h4>Método Exclusivo de Revisão</h4>
                <p>Nosso método de revisão consiste em incríveis aulas de 3 minutos que condensam <b>todo o conteúdo</b> de uma aula completa para otimizar o seu tempo!</p>
              </div>
            </Col>
            <Col sm="6" lg="4">
              <div className="box bg-verde">
                <h4>As 100 Aulas que Mais Caíram</h4>
                <p>Nossa experiente equipe pedagógica selecionou as aulas que mais caíram nos principais vestibulares para você <b>revisar tudo em apenas 5 horas</b>!</p>
              </div>
            </Col>
            <Col sm="6" lg="4">
              <div className="box bg-azul">
                <h4>Aulas Modernas e Atualizadas</h4>
                <p>Todo o nosso conteúdo é gravado em estúdio de última geração, com os conteúdos mais atualizados e utilizando a <b>tecnologia a favor do seu aprendizado</b>.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6" lg="4">
              <div className="box bg-verde">
                <h4>Simulados Turbo</h4>
                <p>Somos a única plataforma do Brasil que possui simulados rápidos de <b>15 questões específicas do seu vestibular</b>, para você poder treinar com foco no que presta e em muito menos tempo!</p>
              </div>
            </Col>
            <Col sm="6" lg="4">
              <div className="box bg-azul">
                <h4>Plantões On-line e Fórum de Dúvidas</h4>
                <p>Professores disponíveis de forma ilimitada todos os dias para <b>tirar as suas dúvidas ao-vivo</b> nos plantões, ou através do nosso fórum de dúvidas.</p>
              </div>
            </Col>
            <Col sm="6" lg="4">
              <div className="box bg-verde">
                <h4>Foco da Principais Universidades</h4>
                <p>Selecionamos o que cai nos principais vestibulares e separamos tudo para que você <b>foque apenas no que precisa</b> e não perca nenhum conteúdo importante.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="depoimentos lp">
        <Container>
          <Row>
            <Col>
              <div className="depoimentos-box">
                <h3>Com a palavra, os <u>aprovados</u>:</h3>
                <Carousel >
                  <Carousel.Item>
                    <Carousel.Caption>
                      <Row>
                        <p>“O Coruja Sábia foi essencial em vários momentos desse ano de estudo. As revisões são rápidas e eficientes, ideais para revisar assuntos ao longo do ano e refrescar a memória em horas que, normalmente, não dariam tempo de o fazer. “</p>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center">
                          <div><img src={Dep1} width="80" alt="Depoimento" className="float-right align-middle" /></div>
                          <div className="nome">Pedro Fábio Luiz<br /><b>Medicina - USP</b></div>
                        </div>
                      </Row>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption>
                      <Row>
                        <p>"Querida equipe do Coruja, somos eternamente gratas pelo apoio que nos garantiu essa conquista em dose dupla! As revisões da plataforma foram essenciais ao nosso desempenho! Foi maravilhoso contar com esse time fenomenal."					</p>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center">
                          <div><img src={Dep2} width="80" alt="Depoimento" className="float-right align-middle" /></div>
                          <div className="nome">Beatriz e Luíza Fernandes<br /><b>Medicina e Direito - USP</b></div>
                        </div>
                      </Row>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption>
                      <Row>
                        <p>"O Coruja Sábia foi essencial para a minha aprovação! Me deu o caminho sobre o que estudar em cada momento e me ajudou a ser bem mais produtivo, porque aproveitei melhor meu tempo com as aulas de 3 minutos."</p>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center">
                          <div><img src={Dep3} width="80" alt="Depoimento" className="float-right align-middle" /></div>
                          <div className="nome">Lucas Andreolli<br /><b>USP - Administração</b></div>
                        </div>
                      </Row>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption>
                      <Row>
                        <p>“Os vídeos do Coruja me ajudaram muito a relembrar os assuntos, principalmente os do primeiro semestre. Mas o que mais gostava eram os Quizzes rápidos do Instagram que sempre deixavam a mente ativa”.</p>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center">
                          <div><img src={Dep4} width="80" alt="Depoimento" className="float-right align-middle" /></div>
                          <div className="nome">Maria Reis<br /><b>USP - Farmácia</b></div>
                        </div>
                      </Row>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption>
                      <Row>
                        <p>"Obrigado de coração a todos que me apoiaram, em especial à plataforma Coruja Sábia, que foi de enorme auxílio com os quizzes e os vídeos rápidos, diretos e com conteúdo de extrema qualidade. "</p>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center">
                          <div><img src={Dep5} width="80" alt="Depoimento" className="float-right align-middle" /></div>
                          <div className="nome">Pedro de Brito<br /><b>Engenharia Agronômica</b></div>
                        </div>
                      </Row>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption>
                      <Row>
                        <p>"Coruja Sábia: Extremamente compacta, incrivelmente completa."</p>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-center">
                          <div><img src={Dep6} width="80" alt="Depoimento" className="float-right align-middle" /></div>
                          <div className="nome">Eduardo Jorge Elias<br /><b>USP - Direito</b></div>
                        </div>
                      </Row>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>

            </Col>
          </Row>
        </Container>
      </section>

      
    </div>
  );
}

export default SuperParana;