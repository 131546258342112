import React from "react";
import "./estilos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/OpenSans-Regular.ttf";
import "./assets/fonts/OpenSans-Bold.ttf";
import "./assets/fonts/Futura.ttc";
import "./assets/fonts/Futura-Bold.ttf";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Topo from "./components/topo";
import Footer from "./components/footer";
import PaginaInicial from "./routes/PaginaInicial";
import TermosdeUso from "./routes/TermosUso";
import PoliticaPrivacidade from "./routes/PoliticaPrivacidade";
import Cancelamento from "./routes/Cancelamento";
import Sobre from "./routes/SobreCorujaSabia";
import SuperParana from "./routes/SuperParana";
import AulasParticulares from "./routes/AulasParticulares";
import CemMais from "./routes/CemMais";
import PreVestibular from "./routes/PreVestibular";
import PreVestibularMed from "./routes/PreVestibularMed";
import ApoioOnline from "./routes/ApoioOnline";

function App() {
  return (
    <Router>
      <Topo />
      <Routes>
        <Route path='/' element={<PaginaInicial />} />
        {/* <Route path='/pre-vestibular' element={<PreVestibular />} /> */}
        {/* <Route path='/aulas-particulares' element={<AulasParticulares />} /> */}
        {/* <Route path='/ultra-revisao' element={<ApoioOnline />} /> */}
        <Route path='/sobre-coruja-sabia' element={<Sobre />} />
        <Route path='/politica-de-privacidade' element={<PoliticaPrivacidade />} />
        <Route path='/termos-de-uso' element={<TermosdeUso />} />
        <Route path='/cancelamento' element={<Cancelamento />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
