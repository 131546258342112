import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";

function Topo() {
  return (
    <div className='navigation'>
      <Navbar collapseOnSelect bg='azul-transparente' expand='lg' fixed='top'>
        <Container>
          <Navbar.Brand href='/'>
            {" "}
            <img
              src={logo}
              width='174'
              className='d-inline-block align-top'
              alt='Coruja Sábia Logo'
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
            <Nav className='mr-auto'>
              <Nav.Link href='/#metodo'>Conheça o Método</Nav.Link>
              {/* <Nav.Link href='/pre-vestibular'>Pré-Vestibular</Nav.Link> */}
              {/* <Nav.Link href='/ultra-revisao'>Ultra Revisão</Nav.Link> */}
              {/* <Nav.Link href='/aulas-particulares'>Aulas Particulares</Nav.Link> */}
            </Nav>

            <a className='btn-login' href='https://aluno.corujasabia.com.br/'>
              <FontAwesomeIcon icon={faGraduationCap} /> Painel do Aluno
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Topo;
