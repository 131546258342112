import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header>
        <Modal.Title className='text-center' id='contained-modal-title-vcenter'>
          Método exclusivo de revisão
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <h4>
          Revise a Matéria em Aulas de <u>3 Minutos</u>!
        </h4>
        <p>
          O Coruja Sábia é o único curso que consegue condensar todo o conteúdo de uma aula regular
          em apenas <b>3 minutos</b>, para que você consiga rever a matéria antes daquela prova, ou
          revisar muito mais conteúdo quando estiver chegando perto dos vestibulares!
        </p>
        <p>Confira e veja como é possível:</p>
        <iframe
          src='https://player.vimeo.com/video/343381693?h=cc81b44811&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
          frameborder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          title='Aula de 3 minutos'
          width='540'
          height='300'></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Voltar para o site</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Planos() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className='planos py-80' id='planos'>
      <Container>
        <Row
          className='text-center offset-lg-2'
          style={{ width: "100%", maxWidth: "1320px", margin: "0", justifyContent: "center" }}>
          <Col lg='4'>
            <div className='card lp box-shadow'>
              <div className='card-header bg-verde'>
                <h4 className='my-0'>Ultra Revisão</h4>
              </div>
              <div className='valor bg-light'>
                <span>
                  De <s>R$1.990,00</s> por: R$1.188,00 em até
                </span>{" "}
                <br />
                <h3 className='card-title pricing-card-title'>
                  <b>
                    <small>12x de</small> R$99,00
                  </b>
                </h3>
                <span>Acesse por 12 meses</span>
              </div>
              <div className='card-body'>
                <ul className='list-unstyled check mt-3 mb-4'>
                  <li>
                    Mais de 1.000{" "}
                    <a href='#aulas' onClick={() => setModalShow(true)}>
                      Aulas de 3 Minutos
                    </a>
                  </li>
                  <li>
                    Plantões Ao-Vivo <b>ILIMITADOS</b>
                  </li>
                  <li>
                    Fórum de Dúvidas <b>ILIMITADAS</b>
                  </li>
                  <li>Plano de Estudos</li>
                  <li>40 Aulas de Conceitos Essenciais</li>
                  <li>Listas de Exercícios</li>
                  <li>Exercícios Resolvidos em Vídeo</li>
                  <li>Resumos em PDF</li>
                  <li>Simulados</li>
                </ul>
                <p>
                  {/* <a
                    href='https://assinatura.corujasabia.com.br/finalizar-assinatura?cart=29'
                    className='btn-assinar'>
                    Comece agora
                  </a> */}
                </p>
                <span className='text-muted'>Acesso por 12 meses</span>
              </div>
            </div>
          </Col>
        </Row>
        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
      </Container>
    </section>
  );
}

export default Planos;
