import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Cancelamento() {

  return (
    <Container>
      <Row>
        <Col className="mt-5 mb-5">
          <h2>Cancelamento</h2><br />
          <p><b>Quando se aplica</b></p>
          <p>Uma vez efetivada a assinatura e realizado o pagamento (à vista ou primeira parcela), este termo poderá ser rescindido pelo contratante, por desistência, no prazo de até 07 (sete) dias após APROVAÇÃO da compra, nos moldes do Código de Defesa do Consumidor, tendo direito ao reembolso completo do valor pago. Não há, após este prazo, devolução de qualquer valor, tenham ou não sido utilizados os serviços existentes, pois o cliente ao aceitar este contrato de licença afirma ter testado e aprovado o sistema e verificado que este atende às suas necessidades.</p>
          <p><b>Para o cancelamento</b></p>
          <p>O membro deverá encaminhar um e-mail solicitando o cancelamento com os seguintes dados: e-mail de cadastro e últimos 4 dígitos do cartão de crédito. Em caso de compra pelo boleto deve ser informado o e-mail de cadastro, nome completo, CPF, banco, agência e conta corrente. O prazo para a empresa realizar o estorno em ambos os casos é de até 60 dias. Uma vez efetuado o cancelamento e solicitado reembolso por parte da empresa, a transferência de valores se torna de responsabilidade do banco.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Cancelamento;