import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Dep1 from '../assets/img/dep-1.png'; 
import Dep2 from '../assets/img/dep-2.png'; 
import Dep3 from '../assets/img/dep-3.png'; 
import Dep4 from '../assets/img/dep-4.png'; 
import Dep5 from '../assets/img/dep-5.png'; 
import Dep6 from '../assets/img/dep-6.png'; 
 
function Depoimentos() {
  
    return (

 <section className="depoimentos lp">
    <Container>
      <Row>
        <Col>
        <div className="depoimentos-box">
         <h3>Com a palavra, os <u>aprovados</u>:</h3>
          <Carousel >
            <Carousel.Item>
              <Carousel.Caption>
                <Row>
                   <p>“O Coruja Sábia foi essencial em vários momentos desse ano de estudo. As revisões são rápidas e eficientes, ideais para revisar assuntos ao longo do ano e refrescar a memória em horas que, normalmente, não dariam tempo de o fazer. “</p>
                </Row>
                <Row>
                    <div className="d-flex justify-content-center">
                      <div><img src={Dep1} width="80" alt="Depoimento" className="float-right align-middle"/></div>
                      <div className="nome">Pedro Fábio Luiz<br /><b>Medicina - USP</b></div>
                    </div>
                 </Row>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <Row>
                   <p>"Querida equipe do Coruja, somos eternamente gratas pelo apoio que nos garantiu essa conquista em dose dupla! As revisões da plataforma foram essenciais ao nosso desempenho! Foi maravilhoso contar com esse time fenomenal."					</p>
                </Row>
                <Row>
                    <div className="d-flex justify-content-center">
                      <div><img src={Dep2} width="80" alt="Depoimento" className="float-right align-middle"/></div>
                      <div className="nome">Beatriz e Luíza Fernandes<br /><b>Medicina e Direito - USP</b></div>
                    </div>
                 </Row>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
              <Row>
                   <p>"O Coruja Sábia foi essencial para a minha aprovação! Me deu o caminho sobre o que estudar em cada momento e me ajudou a ser bem mais produtivo, porque aproveitei melhor meu tempo com as aulas de 3 minutos."</p>
                </Row>
                <Row>
                    <div className="d-flex justify-content-center">
                      <div><img src={Dep3} width="80" alt="Depoimento" className="float-right align-middle"/></div>
                      <div className="nome">Lucas Andreolli<br /><b>USP - Administração</b></div>
                    </div>
                 </Row>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
              <Row>
                   <p>“Os vídeos do Coruja me ajudaram muito a relembrar os assuntos, principalmente os do primeiro semestre. Mas o que mais gostava eram os Quizzes rápidos do Instagram que sempre deixavam a mente ativa”.</p>
                </Row>
                <Row>
                    <div className="d-flex justify-content-center">
                      <div><img src={Dep4} width="80" alt="Depoimento" className="float-right align-middle"/></div>
                      <div className="nome">Maria Reis<br /><b>USP - Farmácia</b></div>
                    </div>
                 </Row>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
              <Row>
                   <p>"Obrigado de coração a todos que me apoiaram, em especial à plataforma Coruja Sábia, que foi de enorme auxílio com os quizzes e os vídeos rápidos, diretos e com conteúdo de extrema qualidade. "</p>
                </Row>
                <Row>
                    <div className="d-flex justify-content-center">
                      <div><img src={Dep5} width="80" alt="Depoimento" className="float-right align-middle"/></div>
                      <div className="nome">Pedro de Brito<br /><b>Engenharia Agronômica</b></div>
                    </div>
                 </Row>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
              <Row>
                   <p>"Coruja Sábia: Extremamente compacta, incrivelmente completa."</p>
                </Row>
                <Row>
                    <div className="d-flex justify-content-center">
                      <div><img src={Dep6} width="80" alt="Depoimento" className="float-right align-middle"/></div>
                      <div className="nome">Eduardo Jorge Elias<br /><b>USP - Direito</b></div>
                    </div>
                 </Row>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        </Col>
      </Row>
    </Container>
  </section>

);
}

export default Depoimentos;