/* eslint-disable no-unused-vars */
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Instagram from '../assets/img/instagram.png';
import Facebook from '../assets/img/facebook.png';
import Tiktok from '../assets/img/tiktok.png';
import Youtube from '../assets/img/youtube.png';
import Google from '../assets/img/google-play.png';
import Apple from '../assets/img/app-store.png';
import Aws from '../assets/img/member-rocket.png';

function Footer() {
  return (
    <div className="footer">
        <footer>
    <Container>
      <Row>
        <Col xs="6" lg="3">
        <h5>Coruja Sábia</h5>
        <ul>
          <li><a href="/sobre-coruja-sabia">Sobre Nós</a></li>
          {/* <li><a href="mailto:contato@corujasabia.com.br">Fale Conosco</a></li> */}
          {/* <li><a href="#link">Blog</a></li> */}
          {/* <li><a href="https://api.whatsapp.com/send?phone=+5511975898698">WhastsApp</a></li> */}
        </ul>
        </Col>
        {/* <Col xs="6" lg="3">
          <h5>Planos</h5>
          <ul>
            <li><a href="/pre-vestibular">Ultra Estudante</a></li>
            <li><a href="/ultra-revisao">Ultra Revisão</a></li>
            <li><a href="/aulas-particulares">Aulas Particulares</a></li>
        </ul>
        </Col> */}
        <Col xs="6" lg="3">
          <h5>Suporte</h5>
          <ul>
            <li><a href="#link">Perguntas Frequentes</a></li>
            <li><a href="/cancelamento">Cancelamento</a></li>
            <li><a href="/termos-de-uso">Termos de Uso</a></li>
            <li><a href="/politica-de-privacidade">Política de Privacidade</a></li>
          </ul>
        </Col>
        {/* <Col xs="6" lg="3">
        <h5>Instale o App</h5>
        <div className="d-flex align-content-start align-items-sm-center">
          <a href="https://apps.apple.com/br/app/coruja-s%C3%A1bia/id1550943964"><img src={Apple} width="100" alt="App Store" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.coruja_sabia&hl=pt"><img src={Google} width="100" alt="Play Store" /></a>
        </div>
        </Col> */}
      </Row>
      <hr />
      <Row>
        <Col lg="6">
        <div className="d-flex align-items-sm-center justify-content-start">
          <a href="https://www.facebook.com/canalcorujasabia"><img src={Facebook} width="30" alt="Rede social" /></a>
          <a href="https://www.instagram.com/coruja.sabia/"><img src={Instagram} width="30" alt="Rede social" /></a>
          <a href="https://www.tiktok.com/canalcorujasabia"><img src={Tiktok} width="30" alt="Rede social" /></a>
          <a href="https://www.youtube.com/channel/UCrmr0gOAbURnZ5A9r1Tu9tw"><img src={Youtube} width="30" alt="Rede social" /></a>
        </div>
        </Col>
        <Col lg="6" className="text-right">&copy; Coruja Sábia {new Date().getFullYear()}. Todos os direitos reservados.</Col>
       
    </Row> 
    {/* <div className="whatsapp_float">
    <a href="https://wa.me/+5511975898698"
       target="_blank"
        rel="noopener noreferrer"
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg> </a>
</div> */}

    </Container>
  </footer>
    </div>
  );
}

export default Footer;