import React from "react";
import { Helmet } from "react-helmet";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import CountdownTimer from "../CountdownTimer";
import Depoimentos from "../components/Depoimentos";

const NINE_HOURS = 8 * 50 * 60 * 1000;
const NOW_IN_MS = new Date().getTime();

const dateTimeAfterNineHours = NOW_IN_MS + NINE_HOURS;

function CemMais() {

  return (
    <div>
      <Helmet>
        <title>Revisão Completa em 5 Horas - Aulas de 3 Minutos</title>
        <meta name="description" content="Mais de 1.000 aulas de revisão de 3 minutos para otimização do tempo durante os estudos." />
      </Helmet>
      <section className="hero bg-azul pb-5">
        <div className="top-spacing"></div>
        <Container>
          <Row>
            <Col lg={8} xs={12}>
              <h1 className="mb-3">Revise em <u>5 horas</u> o que mais cai no seu vestibular!</h1>
              <div className="col-lg-8">
                <h2 className="amarelo">Método de revisão exclusivo com aulas de <span className="bg-amarelo azul rounded px-2">3 minutos</span>!</h2>
                <p className="lead">Condensamos todo o conteúdo cobrado no ENEM e nos principais vestibulares em aulas de <b>3 minutos</b> para que você possa estudar com muito mais eficiência!</p>
               
              </div>
            </Col>
            <Col lg="4">
            <div className="card box-shadow text-center">
              <div className="card-header bg-verde">
                <h4 className="my-0"><b>Ultra Revisão</b></h4>
              </div>
              <div className="valor bg-light">
                <span>De <s>R$1.990,00</s> por R$1.188,00 em até</span> <br />
                <h3 className="card-title pricing-card-title"><b><small>12x de</small> R$99,00</b></h3>
                <span>Acesse por 12 meses</span>
              </div>
              <div className="card-body">
                <ul className="list-unstyled check mt-3">
                  <li>100 Aulas de <b>3 Minutos</b></li>
                  <li>100 Materiais Digitais</li>
                  <li>Conteúdos que <b>mais caíram</b></li>
                  <li><b>Foco</b> no seu vestibular</li>
                  <li>Estude o <b>mais importante</b></li>
                  <li><b>Revise</b> a matéria</li>
                  <li>Listas de exercícios</li>
                  <li>Fórum de dúvidas</li>
                  <li>Exercícios resolvidos em vídeo</li>
                  <li>Plano de estudos</li>
                  <li>Aulas offline pelo App</li>
                </ul>
                <ul className="list-unstyled uncheck pt-1 mb-4">
                  <li><s>Análise de DNA</s></li>
                  <li><s>Consulta com Nutricionista</s></li>
                  <li><s>Jornada de estudos individual</s></li>
                  <li><s>Mentoria</s></li>
                  <li><s>Plantões Ao-vivo</s></li>
                </ul>
              </div>
              <div className="card-footer text-dark py-4 px-5">
                <p>Selecione o seu vestibular:</p>
                {/* <Form action="https://assinatura.corujasabia.com.br/finalizar-assinatura" method="get">
                  <Form.Select aria-label="Selecione o que você presta" size="lg" name="cart">
                    <option value="11">ENEM</option>
                    <option value="12">Fuvest</option>
                    <option value="14">Unicamp</option>
                    <option value="13">Unesp</option>
                    <option value="16">UEL</option>
                    <option value="17">UFPR</option>
                    <option value="15">UFU</option>
                    <option value="21">Unaerp</option>
                    <option value="18">Famerp</option>
                    <option value="20">Famema</option>
                    <option value="19">Fameca</option>
                  </Form.Select>
                  <p><input type="submit" className="btn-assinar mt-3" value="Começar Agora" /></p>
                  <span className="text-muted">Acesse por 12 meses</span>
                </Form> */}
              </div>
            </div>
            </Col>
          </Row>
        </Container>

      </section>
      <section className="bg-verde text-light text-center py-5">
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} xs={12} className="px-5">
              <h2 className="mb-3">Revise a Matéria em Aulas de <span className="bg-azul rounded px-2">3 Minutos</span>!</h2>
              <p>O Coruja Sábia é o único curso que consegue condensar todo o conteúdo de uma aula regular em apenas 3 minutos, para que você consiga rever a matéria antes daquela prova, ou revisar muito mais conteúdo quando estiver chegando perto dos vestibulares!</p>
              <p><b>Confira como é possível:</b></p>
              <iframe src="https://player.vimeo.com/video/343381693?h=cc81b44811&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title="Aula de 3 minutos" width="100%" style={{ height: '30vh' }}></iframe>
            </Col>
          </Row>
        </Container>

      </section>
      <Depoimentos />

    </div>
  );
}

export default CemMais;